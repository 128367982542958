// Initial config for setting up modals
MicroModal.init({
  openTrigger: 'data-custom-open',
  disableScroll: false,
  awaitCloseAnimation: true
})

Pristine.addMessages('en', {
  required: "Este campo é obrigatório.",
  email: "Por favor, forneça um endereço de e-mail válido.",
  number: "Por favor, forneça um número válido.",
  integer: "Por favor, forneça apenas dígitos.",
  url: "Por favor, forneça um URL válido.",
  tel: "Por favor, forneça um número de telefone válido.",
  maxlength: "Por favor, forneça menos do que ${1} caracteres.",
  minlength: "Por favor, forneça mais do que ${1} caracteres.",
  min: "O valor mínimo para este campo é de ${1}.",
  max: "O valor máximo para este campo é de ${1}.",
  pattern: "Por favor, forneça uma informação de acordo com o formato do campo.",
  equals: "Os dois campos não correspondem.",
  default: "Por favor, forneça um valor válido."
})

// STICKY HEADER - IO
function stickyHeader(topVisible) {
  const headerEl = document.querySelector('.header');

  if (topVisible.intersectionRatio !== 1) {
    headerEl.classList.add('header--sticky');
  } else {
    headerEl.classList.remove('header--sticky');
  }
}

// INTERSECTION OBSERVER
const IO = new IntersectionObserver(function (entries) {
  for(let entry of entries) {
    stickyHeader(entry);
  }
})

let topOfPageEl = document.querySelector('.js-top-of-page');

IO.observe(topOfPageEl)

// Guardamos a função numa variável para remover o eventListener quando necessário.
let toggleAccordion = function(event) {
  event.preventDefault();

  const clickedElement = event.target;

  if (clickedElement.classList.contains('accordion-arrow')) {
    clickedElement.parentElement.classList.toggle('nav-menu__item--active');
    return;
  }
  clickedElement.classList.toggle('nav-menu__item--active');
}

// Checa todos accordions e determina qual função a correta para ele.
function menuAccordion(listenerType) {
  let accordions = document.querySelectorAll('.nav-menu__item');

  for (let accordion of accordions) {
    if (listenerType === 'add') {
      accordion.addEventListener('click', toggleAccordion);
    } else {
      accordion.removeEventListener('click', toggleAccordion);
    }
  }
}

// Checando tamanho do hamburger-icon para identificar se estamos no desktop ou mobile.
// Quando ele recebe display: none automaticamente fica com width: 0 e removemos o listener do accordion.
const resizeObserver = new ResizeObserver(function (entries) {
  const element = entries[0].target;
  const elementWidth = entries[0].contentRect.width;

  if (element.classList.contains('hamburger-menu') && elementWidth > 0) {
    menuAccordion('add')
    IO.unobserve(topOfPageEl)
  } else {
    menuAccordion('remove')
    IO.observe(topOfPageEl)
  }
})

const hamburgerMenuEl = document.querySelector('.hamburger-menu')
resizeObserver.observe(hamburgerMenuEl)